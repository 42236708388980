import React, { Component } from 'react'
import firstpage from '../images/firstpage.png'
import previouspage from '../images/previouspage.png'
import nextpage from '../images/nextpage.png'
import lastpage from '../images/lastpage.png'

export default class Paginator extends Component {

    onFirstPageClick(){
        if (this.props.currentPage>1) this.props.onFirstPageClick();
    }

    onPreviousPageClick(){
        if (this.props.currentPage>1) this.props.onPreviousPageClick();
    }

    onNextPageClick(){
        if (this.props.currentPage<this.props.totalPages) this.props.onNextPageClick();
    }

    onLastPageClick(){
        if (this.props.currentPage<this.props.totalPages) this.props.onLastPageClick();
    }


    render() {
        return (
            <div className="paginatorItem">
                <div className="paginatorButton" onClick={this.onFirstPageClick.bind(this)} style={{opacity:this.props.currentPage>1?'1':'0.3',cursor:this.props.currentPage>1?'pointer':'default'}}><img src={firstpage} alt="fp" style={{objectFit:'contain',height:'27px'}} /></div>
                <div className="paginatorButton" onClick={this.onPreviousPageClick.bind(this)} style={{margin:'0px 5px',opacity:this.props.currentPage>1?'1':'0.3',cursor:this.props.currentPage>1?'pointer':'default'}}><img src={previouspage} alt="pp" style={{objectFit:'contain',height:'27px'}} /></div>
                <div style={{margin:'0px 5px',fontSize:'16px',color:'var(--black1)'}}>{this.props.currentPage}&nbsp;از&nbsp;{this.props.totalPages}</div>
                <div className="paginatorButton" onClick={this.onNextPageClick.bind(this)} style={{margin:'0px 5px',opacity:this.props.currentPage<this.props.totalPages?'1':'0.3',cursor:this.props.currentPage<this.props.totalPages?'pointer':'default'}}><img src={nextpage} alt="np" style={{objectFit:'contain',height:'27px'}} /></div>
                <div className="paginatorButton" onClick={this.onLastPageClick.bind(this)} style={{opacity:this.props.currentPage<this.props.totalPages?'1':'0.3',cursor:this.props.currentPage<this.props.totalPages?'pointer':'default'}}><img src={lastpage} alt="lp" style={{objectFit:'contain',height:'27px'}} /></div>
            </div>
        )
    }
}
