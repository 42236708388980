import React, { Component } from 'react'
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
// import Container from '@material-ui/core/Container';
import Logo from '../images/logo.png'
import CircularProgress from '@material-ui/core/CircularProgress';

export default class SignIn extends Component {

  constructor(props){
    super(props);

    this.state={
      userNameErrorText:'',
      passwordErrorText:'',
      buttonDisabled:false,
      userNameValue:'',
      passwordValue:'',
      serverResponse:false,
    }

    this.inputHandleChange=this.inputHandleChange.bind(this)

    this.userInput=React.createRef();
    this.passInput=React.createRef();
  }

  componentDidMount(){
        if (!localStorage.getItem("username") || !localStorage.getItem("token")){
          this.setState({serverResponse:true})
          return;
        }

        let data = {
            username: localStorage.getItem("username"),
            token:localStorage.getItem("token"),
        };

        fetch("/api/getAppInfo",{
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        })
        .then(res => res.json())
        .then(
        (result) => {
            console.log(result);
            if (result.status===200){
              this.props.history.push('/')
            }else{
              this.setState({serverResponse:true})
            }
        })
    }

  onSignIn(){
    if (!this.state.userNameValue){
      this.setState({userNameErrorText:'لطفاً نام کاربری را وارد کنید'},()=>{
        this.userInput.current.focus();
      })
      return
    }else{
      this.setState({userNameErrorText:''})
    }

    if (!this.state.passwordValue){
      this.setState({passwordErrorText:'لطفاً کلمه عبور را وارد کنید'},()=>{
        this.passInput.current.focus();
      })
      return
    }else{
      this.setState({passwordErrorText:''})
    }


    let data = {
      username: this.state.userNameValue,
      password:this.state.passwordValue,
    };

    console.log(data);

    this.setState({buttonDisabled:true})

    fetch("/api/login",{
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    })
    .then(res => res.json())
    .then(
    (result) => {
      console.log(result);
      if (result.status===200){
          localStorage.setItem("token", result.token);
          localStorage.setItem("username", this.state.userNameValue);
          this.props.history.push('/')
      }else if (result.status===401){
        this.setState({passwordErrorText:'نام کاربری یا کلمه عبور اشتباه است',buttonDisabled:false},()=>{
          this.passInput.current.focus();
        })
      }else{
        this.setState({buttonDisabled:false})
      }
    })
  }

  inputHandleChange(e){
    this.setState({[e.target.name]:e.target.value})
  }

  handleKeyPress(target){
    if(target.charCode===13){
      this.onSignIn();  
    }
  }
  
  render() {
    if (this.state.serverResponse){
      return (
        <div className="loginForm">
          <CssBaseline />
          <div className="paper">
            <img src={Logo} alt="" style={{width:'120px',objectFit:'contain',marginBottom:'20px'}} />
            <form className="form" noValidate>
              <div style={{minHeight:'20px'}} />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="username"
                label="نام کاربری"
                name="userNameValue"
                autoFocus
                color="primary"
                helperText={this.state.userNameErrorText}
                value={this.state.userNameValue}
                onChange={this.inputHandleChange}
                disabled={this.state.buttonDisabled}
                onKeyPress={this.handleKeyPress.bind(this)}
                inputRef={this.userInput}
                inputProps={{style: { direction: "ltr" } }}
                dir="ltr"
              />
              <div style={{minHeight:'20px'}} />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="passwordValue"
                label="کلمه عبور"
                type="password"
                id="password"
                color="primary"
                helperText={this.state.passwordErrorText}
                value={this.state.passwordValue}
                onChange={this.inputHandleChange}
                disabled={this.state.buttonDisabled}
                onKeyPress={this.handleKeyPress.bind(this)}
                inputRef={this.passInput}
                inputProps={{style: { direction: "ltr" } }}
                dir="ltr"
              />
              <div style={{minHeight:'20px'}} />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className="submit"
                disabled={this.state.buttonDisabled}
                onClick={this.onSignIn.bind(this)}
              >
                {!this.state.buttonDisabled && <div>ورود</div>}
              </Button>
            </form>          
            {this.state.buttonDisabled && <LinearProgress color="primary" style={{width:'100%',position:'fixed',bottom:'0px'}}/>}
          </div>
        </div>
      )
    }else{
      return <CircularProgress color="primary" />
    }
    
  }
}
