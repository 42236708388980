import React, { Component } from 'react'
import { AgGridReact } from 'ag-grid-react';
import profile from '../images/profile.png'
import logo from '../images/logo.png'
import imageIcon from '../images/imageicon.png'
import Button from '@material-ui/core/Button';
// import MyMenuItem from '../components/MenuItem'
import Paginator from '../components/Paginator'
import CircularProgress from '@material-ui/core/CircularProgress';
import DatePicker, { DateObject } from "react-multi-date-picker"
import moment from 'moment-jalaali'
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
// import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import "react-multi-date-picker/styles/layouts/mobile.css"
import "react-multi-date-picker/styles/layouts/prime.css"
import "react-multi-date-picker/styles/colors/bluee.css"

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import PieChart, {
  Connector,
  Font,
  SmallValuesGrouping,
  Size
} from 'devextreme-react/pie-chart';

import { Chart, Series, CommonSeriesSettings, Label, Legend, Export,ArgumentAxis,ValueAxis } from 'devextreme-react/chart';

import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide'

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';

import { withStyles } from '@material-ui/core/styles';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import axios from "axios";
import FilterIcon from '@material-ui/icons/FilterList';
import ChartIcon from '@material-ui/icons/PieChart';
import DownloadIcon from '@material-ui/icons/CloudDownload';

// DevExpress.config({
//     rtlEnabled: true
// });
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

function StartDateInput({ openCalendar, stringDate, handleValueChange }) {
  return (
    <TextField
        variant="outlined"
        margin="normal"
        size="small"
        
        // inputProps={{style: {fontSize: 12}}}
        // InputLabelProps={{style: {fontSize: 12}}}
        InputProps={{className:"datefield"}}
        InputLabelProps={{className:"datefield"}}
        type="text"
        readOnly
        fullWidth
        label="تاریخ شروع"
        color="primary"
        onFocus={openCalendar}
        value={stringDate}
        onChange={handleValueChange}
    />
  )
}

function EndDateInput({ openCalendar, stringDate, handleValueChange }) {
  return (
    <TextField
        variant="outlined"
        margin="normal"
        size="small"
        // inputProps={{style: {fontSize: 12}}}
        // InputLabelProps={{style: {fontSize: 12}}}
        InputProps={{className:"datefield"}}
        InputLabelProps={{className:"datefield"}}
        type="text"
        readOnly
        fullWidth
        label="تاریخ پایان"
        color="primary"
        onFocus={openCalendar}
        value={stringDate}
        onChange={handleValueChange}
        
    />
  )
}

const useStyles = theme => ({
    root: {
        marginTop:'10px',
    },
    textFieldStyle:{
        fontSize:10
    },
    filterButton:{
        whiteSpace: 'nowrap',
        // fontSize: '12px',
        [theme.breakpoints.up('xs')]: {
            fontSize: '10px',
            minWidth:'25px',
            padding:'6px 10px',
            "& .MuiButton-startIcon": {
                marginRight: '0px',
                marginLeft:'0px',
            }
        },
        [theme.breakpoints.up('middle')]: {
            fontSize: '12px',
            minWidth:'64px',
            padding:'6px 16px',
            "& .MuiButton-startIcon": {
                marginRight: '8px',
                marginLeft:'-4px',
            }
        },
        
        
    },
    selectStyle:{
        whiteSpace: 'nowrap',
        fontSize: '12px',
        // [theme.breakpoints.up('xs')]: {
        //     fontSize: '10px',
        // },
        // [theme.breakpoints.up('sm')]: {
        //     fontSize: '12px',
        // },
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
        width: 220,
        flexShrink: 0,
        },
    },
});

class Dashboard extends Component {

    constructor(props){
        super(props);

        this.state={
            allCategoryInd:false,
            allCategoryCheck:true,
            allEnvironmentInd:false,
            allEnvironmentCheck:true,
            allStandInd:false,
            allStandCheck:true,
            snackbarColor:'var(--red1)',
            snackbarMessage:'',
            snackbarOpen:false,
            passwordDialogOpen:false,
            password1ErrorText:'',
            password1Value:'',
            password2ErrorText:'',
            password2Value:'',
            password3ErrorText:'',
            password3Value:'',
            buttonDisabled:false,
            downloadLoading:false,
            width:0,
            height:0,
            chartLoading:false,
            argumentField:'',
            valueField:'',
            chartTitle:'',
            chartData:[],
            showChart:false,
            openStandList:false,
            openCategoryList:false,
            openEnvironmentList:false,
            categoryList:[],
            environmentList:[],
            productReportTypes:[],
            standList:[],
            serverResponse:false,
            dateValue: moment().add(0,'days').format('jYYYY/jMM/jDD'),
            dateValue1: moment().add(-1,'months').format('jYYYY/jMM/jDD'),
            maxDate: moment().add(0,'days').format('jYYYY/jMM/jDD'),
            maxDateStart: moment().add(0,'days').format('jYYYY/jMM/jDD'),
            minDate:null,
            standValue:[],
            categoryValue:[],
            environmentValue:[],
            typeValue:'all',
            dialogOpen:false,
            mobileOpen:false,
            // categoryReport:[],
            // productReport:[],
            // contactReport:[],
            // uptimeReport:[],
            // likedReport:[],
            // sharedReport:[],
            rowData:[],
            columnDefs:[],
            categoryColumnDefs:[{
                headerName: "عنوان", field: "title",sortable:true,filter:false,resizable: true,cellStyle:{display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:'0px 1px 1px 0px',borderColor:'var(--black3)'}, lockPosition: true
            },{
                headerName: "تعداد", field: "count",sortable:true,filter:false,resizable: true,cellStyle:{display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:'0px 1px 1px 1px',borderColor:'var(--black3)'}, lockPosition: true
            }],
            productColumnDefs:[{
                headerName: "نام محصول", field: "product_name",sortable:true,filter:false,resizable: true,cellStyle:{display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:'0px 1px 1px 0px',borderColor:'var(--black3)'}, lockPosition: true
            },{
                headerName: "کد محصول", field: "code",sortable:true,filter:false,resizable: true,cellStyle:{display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:'0px 1px 1px 0px',borderColor:'var(--black3)'}, lockPosition: true
            },{
                headerName: "تعداد", field: "count",sortable:true,filter:false,resizable: true,cellStyle:{display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:'0px 1px 1px 1px',borderColor:'var(--black3)'}, lockPosition: true
            }],
            contactColumnDefs:[{
                headerName: "نام و نام خانوادگی", field: "first_name",cellRenderer:'fotmatNames',sortable:true,filter:false,resizable: true,cellStyle:{display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:'0px 1px 1px 0px',borderColor:'var(--black3)'}, lockPosition: true
            },{
                headerName: "موبایل", field: "mobile",sortable:true,filter:false,resizable: true,cellStyle:{display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:'0px 1px 1px 1px',borderColor:'var(--black3)'}, lockPosition: true
            },{
                headerName: "ایمیل", field: "email",sortable:true,filter:false,resizable: true,cellStyle:{display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:'0px 1px 1px 1px',borderColor:'var(--black3)'}, lockPosition: true
            },{
                headerName: "شهر", field: "city",sortable:true,filter:false,resizable: true,cellStyle:{display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:'0px 1px 1px 1px',borderColor:'var(--black3)'}, lockPosition: true
            },{
                headerName: "زمینه فعالیت", field: "activity",sortable:true,filter:false,resizable: true,cellStyle:{display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:'0px 1px 1px 1px',borderColor:'var(--black3)'}, lockPosition: true
            },{
                headerName: "تاریخ", field: "date",sortable:true,filter:false,resizable: true,cellStyle:{display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:'0px 1px 1px 1px',borderColor:'var(--black3)'}, lockPosition: true
            }],
            uptimeColumnDefs:[{
                headerName: "نام دستگاه", field: "title",sortable:true,filter:false,resizable: true,cellStyle:{display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:'0px 1px 1px 0px',borderColor:'var(--black3)'}, lockPosition: true
            },{
                headerName: "زمان کارکرد", field: "working_time",cellRenderer:'formatTime',sortable:true,filter:false,resizable: true,cellStyle:{display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:'0px 1px 1px 1px',borderColor:'var(--black3)'}, lockPosition: true
            },{
                headerName: "زمان بلااستفاده", field: "standby_time",cellRenderer:'formatTime',sortable:true,filter:false,resizable: true,cellStyle:{display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:'0px 1px 1px 1px',borderColor:'var(--black3)'}, lockPosition: true
            }],
            likeColumnDefs:[{
                headerName: "عنوان", field: "title",sortable:true,filter:false,resizable: true,cellStyle:{display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:'0px 1px 1px 0px',borderColor:'var(--black3)'}, lockPosition: true
            },{
                headerName: "تعداد", field: "count",sortable:true,filter:false,resizable: true,cellStyle:{display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:'0px 1px 1px 1px',borderColor:'var(--black3)'}, lockPosition: true
            },{
                headerName: "عکس", field: "image",cellRenderer:'fotmatImage',sortable:true,filter:false,resizable: true,cellStyle:{display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:'0px 1px 1px 1px',borderColor:'var(--black3)'}, lockPosition: true
            }],
            accColumnDefs:[{
                headerName: "عنوان", field: "title",sortable:true,filter:false,resizable: true,cellStyle:{display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:'0px 1px 1px 0px',borderColor:'var(--black3)'}, lockPosition: true
            },{
                headerName: "تعداد", field: "count",sortable:true,filter:false,resizable: true,cellStyle:{display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:'0px 1px 1px 1px',borderColor:'var(--black3)'}, lockPosition: true
            },{
                headerName: "عکس", field: "image",cellRenderer:'fotmatImage',sortable:true,filter:false,resizable: true,cellStyle:{display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:'0px 1px 1px 1px',borderColor:'var(--black3)'}, lockPosition: true
            }],
            sepColumnDefs:[{
                headerName: "عنوان", field: "title",sortable:true,filter:false,resizable: true,cellStyle:{display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:'0px 1px 1px 0px',borderColor:'var(--black3)'}, lockPosition: true
            },{
                headerName: "موبایل", field: "phone",sortable:true,filter:false,resizable: true,cellStyle:{display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:'0px 1px 1px 1px',borderColor:'var(--black3)'}, lockPosition: true
            },{
                headerName: "ایمیل", field: "email",sortable:true,filter:false,resizable: true,cellStyle:{display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:'0px 1px 1px 1px',borderColor:'var(--black3)'}, lockPosition: true
            },{
                headerName: "نوع", field: "type",sortable:true,filter:false,resizable: true,cellStyle:{display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:'0px 1px 1px 1px',borderColor:'var(--black3)'}, lockPosition: true
            },{
                headerName: "عکس", field: "image",cellRenderer:'fotmatImage',sortable:true,filter:false,resizable: true,cellStyle:{display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:'0px 1px 1px 1px',borderColor:'var(--black3)'}, lockPosition: true
            },{
                headerName: "تاریخ", field: "date",sortable:true,filter:false,resizable: true,cellStyle:{display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:'0px 1px 1px 1px',borderColor:'var(--black3)'}, lockPosition: true
            }],
            frameworkComponents:{
                fotmatNames:this.fotmatNames,
                fotmatImage:this.fotmatImage,
                formatTime:this.formatTime,
            },
            gridLoading:false,
            menuSelectedIndex:0,
            sharedTypeValue:'accumulative',
            currentPage:1,
            totalPages:1,
            sortList:[],
            
            categorySort:["تعداد - نزولی","تعداد - صعودی"],
            sepSort:["عنوان - صعودی","عنوان - نزولی","تاریخ - صعودی","تاریخ - نزولی"],
            contactSort:["نام و نام خانوادگی - صعودی","نام و نام خانوادگی - نزولی","شهر - صعودی","شهر - نزولی","تاریخ - صعودی","تاریخ - نزولی"],
            upTimeSort:["زمان کارکرد - نزولی","زمان کارکرد - صعودی","زمان بلااستفاده - نزولی","زمان بلااستفاده - صعودی"],
            
            sortValue:null,
            gridLocaleText: {     
                selectAll: 'انتخاب همه',
                selectAllSearchResults: 'انتخاب همه نتایج جستجو',
                searchOoo: 'جستجو...',
                blanks: 'خالی',
                noMatches: 'پشدا نشد',
                filterOoo: 'فیلتر...',
                equals: 'مساوی',
                notEqual: 'نا مساوی',
                lessThan: 'کمتر از',
                greaterThan: 'بیشتر از',
                lessThanOrEqual: 'کوچکتر مساوی',
                greaterThanOrEqual: 'بزرگتر مساوی',
                inRange: 'در محدوده',
                inRangeStart: 'به',
                inRangeEnd: 'از',
                contains: 'شامل',
                notContains: 'شامل نباشد',
                startsWith: 'شروع با',
                endsWith: 'پایان با',
                andCondition: 'و',
                orCondition: 'یا',
                applyFilter: 'تایید',
                resetFilter: 'راه اندازی مجدد',
                clearFilter: 'پاک کردن',
                cancelFilter: 'انصراف',
                columns: 'ستون ها',
                filters: 'فیلترها',   
                group: 'گروه',
                loadingOoo: 'لطفاً شکیبا باشید...',
                noRowsToShow: 'هیچ موردی یافت نشد',
                enabled: 'فعال',
                sum: 'جمع',
                min: 'کمترین',
                max: 'بیشترین',
                none: 'None',
                count: 'تعداد',
                avg: 'میانگین',
                filteredRows: 'فیلتر شده',
                selectedRows: 'انتخاب شده',
                totalRows: 'جمع ردیف ها',
                totalAndFilteredRows: 'ردیف ها',
                page: 'صفحه',
                more: 'بیشتر',
                to: 'تا',
                of: 'از',
                next: 'بعدی',
                last: 'آخرین',
                first: 'اولین',
                previous: 'قبلی',
            }
        }

        this.datepickerr1=React.createRef();
        this.tableGrid=React.createRef();
        this.pass1Input=React.createRef();
        this.pass2Input=React.createRef();
        this.pass3Input=React.createRef();

        this.fixNumber=this.fixNumber.bind(this);
        this.getCategory=this.getCategory.bind(this);
        this.getProducts=this.getProducts.bind(this);
        this.getContacts=this.getContacts.bind(this);
        this.getLiked=this.getLiked.bind(this);
        this.getUpTime=this.getUpTime.bind(this);
        this.getShared=this.getShared.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.formatTime2=this.formatTime2.bind(this)
        this.inputHandleChange=this.inputHandleChange.bind(this)
    }

    onGridReady(){
        try {
            var allColumnIds = [];
            this.tableGrid.current.columnApi.getAllColumns().forEach(function(column) {
                allColumnIds.push(column.colId);
            });
            this.tableGrid.current.columnApi.autoSizeColumns(allColumnIds,false);
        } catch (error) {
        }
    }

    formatTime(params){
        // return new Date(params.value * 1000).toISOString().substr(11, 8)
        // return params.value
        let d = Number(params.value);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);
        return h.toString().padStart(2, '0') +":"+ m.toString().padStart(2, '0') +":"+ s.toString().padStart(2, '0'); 
        
    }

    formatTime2(params){
        // return new Date(params.value * 1000).toISOString().substr(11, 8)
        // return params.value
        if (this.state.menuSelectedIndex===3){
            let d = Number(params.value);
            var h = Math.floor(d / 3600);
            var m = Math.floor(d % 3600 / 60);
            var s = Math.floor(d % 3600 % 60);
            return h.toString().padStart(2, '0') +":"+ m.toString().padStart(2, '0') +":"+ s.toString().padStart(2, '0'); 
        }else{
            return params.value
        }
    }

    fotmatNames(params){
            // let formattedValue="-";
            // if (params.value!==null && params.value>0){
            //     formattedValue = params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            // }
            // console.log(params);
            // let value=0;
            // if (params.value!==null && params.value!=='null' && params.value!==undefined) params.value="0";

            return params.value + ' ' + params.data.last_name;
            // let formattedValue="";
            // if (params.value!==null && params.value!=='null' && params.value!==undefined && params.value!==''){
            //     formattedValue = params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            // }
            // return <div style={{flex:'1',display:'flex',flexDirection:'row-reverse',width:'100%',justifyContent:'space-between'}}>
            //     {(params.value==='null' || params.value==='' || params.value===null) && <div style={{flex:'1',textAlign:'center',color:'black'}} >-</div>}
            //     {params.value!=='null' && params.value!=='' && params.value!==null && params.value!==0 && <div style={{flex:'1',textAlign:params.data.change_percent!==undefined?'right':'center'}} >{formattedValue}</div>}
            //     {params.value!=='null' && params.value!=='' && params.value!==null && params.value===0 && <div style={{flex:'1',textAlign:params.data.change_percent!==undefined?'right':'center'}} >-</div>}
            //     {params.data.change_percent!==undefined && <div style={{minWidth:'10px'}} />}
            //     {params.data.change_percent!==undefined && params.data.change_percent!==0 && <div style={{flex:'1',display:'flex',flexDirection:'row',textAlign:'right',color:params.data.change_percent>0?'#35d494':'#ed2f59',fontSize:'12px'}}>(&nbsp;%&nbsp;{params.data.change_percent})</div>}
            //     {/* {params.data.change_percent<0 && <div style={{color:'#ed2f59',fontSize:'11px',margin:'0px 2px'}}><FontAwesomeIcon icon={faLongArrowAltDown} /></div>}{params.data.change_percent>0 && <div style={{color:'#35d494',fontSize:'11px',margin:'0px 2px'}}><FontAwesomeIcon icon={faLongArrowAltUp} /></div>} */}
            //     {params.data.change_percent!==undefined && params.data.change_percent===0 && <div style={{flex:'1',display:'flex',flexDirection:'row',textAlign:'center',color:'black',fontSize:'10px',justifyContent:'center',alignItems:'center'}}>-</div>}
            // </div>
    }
    
    onOpenImage(link){
        window.open(link)
    }


    fotmatImage(params){
        return <img src={imageIcon} style={{maxHeight:'13px',objectFit:'contain',margin:'0',padding:'0',cursor:'pointer'}} alt="" onClick={()=>window.open("https://cp.novinchoob.com/uploads/"+params.value)} />
        // return <img src={"https://cp.novinchoob.com/uploads/"+params.value} style={{minWidth:'150px',objectFit:'contain',margin:'0',padding:'0'}} alt="" />
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        try {
            this.setState({ width: window.innerWidth, height: window.innerHeight });
        } catch (error) {
        }
    }

    componentDidMount(){
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

        let data = {
            username: localStorage.getItem("username"),
            token:localStorage.getItem("token"),
        };

        fetch("/api/getAppInfo",{
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        })
        .then(res => res.json())
        .then(
        (result) => {
            console.log(result);
            if (result.status===200){

                let standList=JSON.parse(JSON.stringify(result.result.standList));
                let standValue=[];

                standList.forEach(element => {
                    element.selected=true;
                });

                let categoryList=JSON.parse(JSON.stringify(result.result.categoryList));
                categoryList.forEach(element => {
                    element.selected=true;
                });

                let environmentList=JSON.parse(JSON.stringify(result.result.environmentList));
                environmentList.forEach(element => {
                    element.selected=true;
                });

                let productReportTypes=JSON.parse(JSON.stringify(result.result.productReportTypes));
                productReportTypes.forEach(element => {
                    element.selected=true;
                });

                console.log('standList',standList);

                this.setState({categoryList:categoryList,environmentList:environmentList,productReportTypes:productReportTypes,standList:standList,standValue:standValue},()=>{
                    this.getCategory(1,"count","DESC",this.state.categorySort[0],false)
                })
            }else{
                localStorage.clear();
                this.props.history.push('/login')
            }
        })
    }


    getCategory(page,sortColumn,sortOrder,sortValue,all,download){
        let standList=[]
        this.state.standList.forEach(element => {
            if (element.selected) standList.push(element._id);
        });

        let filter=[];
        this.setState({chartData:[]})
        if (!all)
        {
            if (download){
                filter=[{
                    title: "sort",
                    items: {
                        sortColumn: sortColumn,
                        sortOrder: sortOrder
                    }
                }]
            }else{
                filter=[{
                    title: "paginate",
                    items: {
                        "page": page,
                        "count": 30
                    }
                },
                {
                    title: "sort",
                    items: {
                        sortColumn: sortColumn,
                        sortOrder: sortOrder
                    }
                }]
            }
        }

        let data = {
            username: localStorage.getItem("username"),
            token:localStorage.getItem("token"),
            startDate:this.fixNumber(new DateObject(this.state.dateValue1).format('YYYY-MM-DD')),
            endDate:this.fixNumber(new DateObject(this.state.dateValue).format('YYYY-MM-DD')),
            standArray:standList,
            filterArray:filter,
            toXls:download
        };

        if (!all){
            if (download){
                this.setState({downloadLoading:true})
            }else{
                this.setState({gridLoading:true})
            }
        }

        if (download){
            fetch("/api/getCategory",{
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            })
            .then(res => res.body)
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => URL.createObjectURL(blob))
            .then(url => {
                const link = document.createElement("a");
                link.href = url;
                link.download ="Novin-Category.xlsx";
                document.body.appendChild(link);
                link.dispatchEvent(
                    new MouseEvent('click', { 
                    bubbles: true, 
                    cancelable: true, 
                    view: window 
                    })
                );
                document.body.removeChild(link);
                this.setState({downloadLoading:false})
            })
            .catch(err=>{
            })

            return
        }

        fetch("/api/getCategory",{
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        })
        .then(res => res.json())
        .then(
        (result) => {
            console.log(result);
            if (result.status===200){
                let fullpage=Math.floor(result.totalItems/30);
                let reminder=Math.floor(result.totalItems%30);

                if (reminder>0) fullpage+=1;

                if (all) this.setState({chartData:result.result,chartLoading:false})
                else this.setState({serverResponse:true,gridLoading:false,columnDefs:this.state.categoryColumnDefs,rowData:result.result,currentPage:page,totalPages:fullpage,sortList:this.state.categorySort,sortValue:sortValue})
            }else{
                localStorage.clear();
                this.props.history.push('/login')
            }
        })
    }


    getProducts(page,sortColumn,sortOrder,sortValue,all,download){
        let standList=[]
        this.state.standList.forEach(element => {
            if (element.selected) standList.push(element._id.toString());
        });

        let type=[];
        if (this.state.typeValue==='all'){
            type=["like","select"]
        }else{
            type.push(this.state.typeValue)
        }


        let categoryList=[]
        this.state.categoryList.forEach(element => {
            if (element.selected) categoryList.push(element._id);
        });


        let filter=[];
        this.setState({chartData:[]})
        if (!all)
        {
            if (download){
                filter=[{
                    title: "sort",
                    items: {
                        sortColumn: sortColumn,
                        sortOrder: sortOrder
                    }
                }]
            }else{
                filter=[{
                    title: "paginate",
                    items: {
                        "page": page,
                        "count": 30
                    }
                },
                {
                    title: "sort",
                    items: {
                        sortColumn: sortColumn,
                        sortOrder: sortOrder
                    }
                }]
            }
        }

        let data = {
            username: localStorage.getItem("username"),
            token:localStorage.getItem("token"),
            startDate:this.fixNumber(new DateObject(this.state.dateValue1).format('YYYY-MM-DD')),
            endDate:this.fixNumber(new DateObject(this.state.dateValue).format('YYYY-MM-DD')),
            standArray:standList,
            typeArray:type,
            categoryArray:categoryList,
            filterArray:filter,
            toXls:download
        };

        console.log(data);

        if (!all){
            if (download){
                this.setState({downloadLoading:true})
            }else{
                this.setState({gridLoading:true})
            }
        }

        if (download){
            fetch("/api/getProduct",{
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            })
            .then(res => res.body)
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => URL.createObjectURL(blob))
            .then(url => {
                const link = document.createElement("a");
                link.href = url;
                link.download ="Novin-Products.xlsx";
                document.body.appendChild(link);
                link.dispatchEvent(
                    new MouseEvent('click', { 
                    bubbles: true, 
                    cancelable: true, 
                    view: window 
                    })
                );
                document.body.removeChild(link);
                this.setState({downloadLoading:false})
            })
            .catch(err=>{
            })

            return
        }

        fetch("/api/getProduct",{
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        })
        .then(res => res.json())
        .then(
        (result) => {
            console.log(result);
            if (result.status===200){
                let fullpage=Math.floor(result.totalItems/30);
                let reminder=Math.floor(result.totalItems%30);
                if (reminder>0) fullpage+=1;

                if (all) this.setState({chartData:result.result,chartLoading:false})
                else this.setState({gridLoading:false,columnDefs:this.state.productColumnDefs,rowData:result.result,currentPage:page,totalPages:fullpage,sortList:this.state.categorySort,sortValue:sortValue})
            }else{
                localStorage.clear();
                this.props.history.push('/login')
            }
        })
    }


    getContacts(page,sortColumn,sortOrder,sortValue,download){
        let standList=[]
        this.state.standList.forEach(element => {
            if (element.selected) standList.push(element._id);
        });

        let filter=[];
        this.setState({chartData:[]})
        if (download){
            filter=[{
                title: "sort",
                items: {
                    sortColumn: sortColumn,
                    sortOrder: sortOrder
                }
            }]
        }else{
            filter=[{
                title: "paginate",
                items: {
                    "page": page,
                    "count": 30
                }
            },
            {
                title: "sort",
                items: {
                    sortColumn: sortColumn,
                    sortOrder: sortOrder
                }
            }]
        }

        let data = {
            username: localStorage.getItem("username"),
            token:localStorage.getItem("token"),
            startDate:this.fixNumber(new DateObject(this.state.dateValue1).format('YYYY-MM-DD')),
            endDate:this.fixNumber(new DateObject(this.state.dateValue).format('YYYY-MM-DD')),
            standArray:standList,
            filterArray:filter,
            toXls:download,
        };

        console.log(data);

        if (download){
            this.setState({downloadLoading:true})
        }else{
            this.setState({gridLoading:true})
        }

        if (download){
            fetch("/api/getContact",{
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            })
            .then(res => res.body)
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => URL.createObjectURL(blob))
            .then(url => {
                const link = document.createElement("a");
                link.href = url;
                link.download ="Novin-Contacts.xlsx";
                document.body.appendChild(link);
                link.dispatchEvent(
                    new MouseEvent('click', { 
                    bubbles: true, 
                    cancelable: true, 
                    view: window 
                    })
                );
                document.body.removeChild(link);
                this.setState({downloadLoading:false})
            })
            .catch(err=>{
            })

            return
        }

        fetch("/api/getContact",{
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        })
        .then(res => res.json())
        .then(
        (result) => {
            console.log(result);
            if (result.status===200){
                let fullpage=Math.floor(result.totalItems/30);
                let reminder=Math.floor(result.totalItems%30);
                if (reminder>0) fullpage+=1;

                this.setState({gridLoading:false,columnDefs:this.state.contactColumnDefs,rowData:result.result,currentPage:page,totalPages:fullpage,sortList:this.state.contactSort,sortValue:sortValue})
            }else{
                localStorage.clear();
                this.props.history.push('/login')
            }
        })
    }


    getUpTime(page,sortColumn,sortOrder,sortValue,all,download){
        let standList=[]
        this.state.standList.forEach(element => {
            if (element.selected) standList.push(element._id);
        });


        let filter=[];
        this.setState({chartData:[]})
        if (!all)
        {
            if (download){
                filter=[{
                    title: "sort",
                    items: {
                        sortColumn: sortColumn,
                        sortOrder: sortOrder
                    }
                }]
            }else{
                filter=[{
                    title: "paginate",
                    items: {
                        "page": page,
                        "count": 30
                    }
                },
                {
                    title: "sort",
                    items: {
                        sortColumn: sortColumn,
                        sortOrder: sortOrder
                    }
                }]
            }
        }


        let data = {
            username: localStorage.getItem("username"),
            token:localStorage.getItem("token"),
            startDate:this.fixNumber(new DateObject(this.state.dateValue1).format('YYYY-MM-DD')),
            endDate:this.fixNumber(new DateObject(this.state.dateValue).format('YYYY-MM-DD')),
            standArray:standList,
            filterArray:filter,
            toXls:download,
        };

        console.log(data);

        if (!all){
            if (download){
                this.setState({downloadLoading:true})
            }else{
                this.setState({gridLoading:true})
            }
        }

        if (download){
            fetch("/api/getUptime",{
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            })
            .then(res => res.body)
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => URL.createObjectURL(blob))
            .then(url => {
                const link = document.createElement("a");
                link.href = url;
                link.download ="Novin-UpTime.xlsx";
                document.body.appendChild(link);
                link.dispatchEvent(
                    new MouseEvent('click', { 
                    bubbles: true, 
                    cancelable: true, 
                    view: window 
                    })
                );
                document.body.removeChild(link);
                this.setState({downloadLoading:false})
            })
            .catch(err=>{
            })

            return
        }

        axios.post("/api/getUptime", data, { timeout: 200000 })
        .then(result => {
            console.log(result.data);
            if (result.data.status===200){
                let fullpage=Math.floor(result.data.totalItems/30);
                let reminder=Math.floor(result.data.totalItems%30);
                if (reminder>0) fullpage+=1;

                if (all) this.setState({chartData:result.data.result},()=>{
                    this.setState({chartLoading:false})
                })
                else this.setState({gridLoading:false,columnDefs:this.state.uptimeColumnDefs,rowData:result.data.result,currentPage:page,totalPages:fullpage,sortList:this.state.upTimeSort,sortValue:sortValue})
            }else{
                localStorage.clear();
                this.props.history.push('/login')
            }
        })
    }


    getLiked(page,sortColumn,sortOrder,sortValue,all,download){
        let standList=[]
        this.state.standList.forEach(element => {
            if (element.selected) standList.push(element._id);
        });

        let environmentList=[]
        this.state.environmentList.forEach(element => {
            if (element.selected) environmentList.push(element._id);
        });


        let filter=[];
        this.setState({chartData:[]})
        if (!all)
        {
            if (download){
                filter=[{
                    title: "sort",
                    items: {
                        sortColumn: sortColumn,
                        sortOrder: sortOrder
                    }
                }]
            }else{
                filter=[{
                    title: "paginate",
                    items: {
                        "page": page,
                        "count": 30
                    }
                },
                {
                    title: "sort",
                    items: {
                        sortColumn: sortColumn,
                        sortOrder: sortOrder
                    }
                }]
            }
        }


        let data = {
            username: localStorage.getItem("username"),
            token:localStorage.getItem("token"),
            startDate:this.fixNumber(new DateObject(this.state.dateValue1).format('YYYY-MM-DD')),
            endDate:this.fixNumber(new DateObject(this.state.dateValue).format('YYYY-MM-DD')),
            standArray:standList,
            environmentArray:environmentList,
            filterArray:filter,
            toXls:download,
        };

        console.log(data);

        if (!all){
            if (download){
                this.setState({downloadLoading:true})
            }else{
                this.setState({gridLoading:true})
            }
        }

        if (download){
            fetch("/api/getLikedDecoration",{
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            })
            .then(res => res.body)
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => URL.createObjectURL(blob))
            .then(url => {
                const link = document.createElement("a");
                link.href = url;
                link.download ="Novin-Liked.xlsx";
                document.body.appendChild(link);
                link.dispatchEvent(
                    new MouseEvent('click', { 
                    bubbles: true, 
                    cancelable: true, 
                    view: window 
                    })
                );
                document.body.removeChild(link);
                this.setState({downloadLoading:false})
            })
            .catch(err=>{
            })

            return
        }

        fetch("/api/getLikedDecoration",{
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        })
        .then(res => res.json())
        .then(
        (result) => {
            console.log(result);
            if (result.status===200){
                let fullpage=Math.floor(result.totalItems/30);
                let reminder=Math.floor(result.totalItems%30);
                if (reminder>0) fullpage+=1;

                if (all) this.setState({chartData:result.result,chartLoading:false})
                else this.setState({gridLoading:false,columnDefs:this.state.likeColumnDefs,rowData:result.result,currentPage:page,totalPages:fullpage,sortList:this.state.categorySort,sortValue:sortValue})
            }else{
                localStorage.clear();
                this.props.history.push('/login')
            }
        })
    }


    getShared(page,sortColumn,sortOrder,sortValue,all,download){
        let standList=[]
        this.state.standList.forEach(element => {
            if (element.selected) standList.push(element._id);
        });

        let environmentList=[]
        this.state.environmentList.forEach(element => {
            if (element.selected) environmentList.push(element._id);
        });


        let filter=[];
        this.setState({chartData:[]})
        if (!all)
        {
            if (download){
                filter=[{
                    title: "sort",
                    items: {
                        sortColumn: sortColumn,
                        sortOrder: sortOrder
                    }
                }]
            }else{
                filter=[{
                    title: "paginate",
                    items: {
                        "page": page,
                        "count": 30
                    }
                },
                {
                    title: "sort",
                    items: {
                        sortColumn: sortColumn,
                        sortOrder: sortOrder
                    }
                }]
            }
        }


        let data = {
            username: localStorage.getItem("username"),
            token:localStorage.getItem("token"),
            startDate:this.fixNumber(new DateObject(this.state.dateValue1).format('YYYY-MM-DD')),
            endDate:this.fixNumber(new DateObject(this.state.dateValue).format('YYYY-MM-DD')),
            standArray:standList,
            environmentArray:environmentList,
            type:this.state.sharedTypeValue,
            filterArray:filter,
            toXls:download,
        };

        console.log(data);

        if (!all){
            if (download){
                this.setState({downloadLoading:true})
            }else{
                this.setState({gridLoading:true})
            }
        }

        if (download){
            fetch("/api/getSharedDecoration",{
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            })
            .then(res => res.body)
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => URL.createObjectURL(blob))
            .then(url => {
                const link = document.createElement("a");
                link.href = url;
                link.download ="Novin-Shared.xlsx";
                document.body.appendChild(link);
                link.dispatchEvent(
                    new MouseEvent('click', { 
                    bubbles: true, 
                    cancelable: true, 
                    view: window 
                    })
                );
                document.body.removeChild(link);
                this.setState({downloadLoading:false})
            })
            .catch(err=>{
            })

            return
        }

        fetch("/api/getSharedDecoration",{
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        })
        .then(res => res.json())
        .then(
        (result) => {
            console.log(result);
            if (result.status===200){
                let fullpage=Math.floor(result.totalItems/30);
                let reminder=Math.floor(result.totalItems%30);
                if (reminder>0) fullpage+=1;

                if (all) this.setState({chartData:result.result,chartLoading:false})
                else this.setState({gridLoading:false,columnDefs:this.state.sharedTypeValue==='accumulative'?this.state.accColumnDefs:this.state.sepColumnDefs,rowData:result.result,currentPage:page,totalPages:fullpage,sortList:this.state.sharedTypeValue==='accumulative' ? this.state.categorySort:this.state.sepSort,sortValue:sortValue})                
            }else{
                localStorage.clear();
                this.props.history.push('/login')
            }
        })
    }
    

    dateChange(dateValue){
        try {
            this.datepickerr.current.closeCalendar();
            this.datepickerr1.current.closeCalendar();
        } catch (error) {
            
        }
        let value=this.fixNumber(new DateObject(dateValue).format('YYYY/MM/DD'))

        this.setState({ dateValue: value,maxDateStart:value})
    }

    fixNumber(str){
        var persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g],
            arabicNumbers  = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g]
        if(typeof str === 'string')
        {
            for(var i=0; i<10; i++)
            {
                str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
            }
        }
        return str;
    }

    dateChange1(dateValue){
        
        try {
            this.datepickerr.current.closeCalendar();
            this.datepickerr1.current.closeCalendar();
        } catch (error) {
            
        }
        let value=this.fixNumber(new DateObject(dateValue).format('YYYY/MM/DD'))

        let min=this.fixNumber(new DateObject(dateValue).format('YYYY/MM/DD'));
        this.setState({ dateValue1: value,minDate:min})
    }

    standHandleChange(id){
        let temp=JSON.parse(JSON.stringify(this.state.standList));
        temp.forEach(element => {
            if (element._id===id){
                if (!element.selected) element.selected=true;
                else element.selected=false;
            }
        });

        let ind=false;
        temp.forEach(element => {
            if (!element.selected) ind=true
        });

        let found=false;
        temp.forEach(element => {
            if (element.selected) {
                found=true
            }
        });


        let allcheck=true
        if (!found){
            allcheck=false;
            ind=false;
        }

        this.setState({standList:temp,allStandInd:ind,allStandCheck:allcheck})
    }

    standHandleAllChange(){
        this.setState({allStandCheck:!this.state.allStandCheck},()=>{
            let temp=JSON.parse(JSON.stringify(this.state.standList));
            temp.forEach(element => {
                element.selected=this.state.allStandCheck;
            });

            this.setState({standList:temp,allStandInd:false})
        })
    }


    categoryHandleChange(id){
        let temp=JSON.parse(JSON.stringify(this.state.categoryList));
        temp.forEach(element => {
            if (element._id===id){
                if (!element.selected) element.selected=true;
                else element.selected=false;
            }
        });

        let ind=false;
        temp.forEach(element => {
            if (!element.selected) ind=true
        });

        let found=false;
        temp.forEach(element => {
            if (element.selected) {
                found=true
            }
        });


        let allcheck=true
        if (!found){
            allcheck=false;
            ind=false;
        }

        this.setState({categoryList:temp,allCategoryInd:ind,allCategoryCheck:allcheck})
    }

    categoryHandleAllChange(){
        this.setState({allCategoryCheck:!this.state.allCategoryCheck},()=>{
            let temp=JSON.parse(JSON.stringify(this.state.categoryList));
            temp.forEach(element => {
                element.selected=this.state.allCategoryCheck;
            });

            this.setState({categoryList:temp,allCategoryInd:false})
        })
    }

    environmentHandleChange(id){
        let temp=JSON.parse(JSON.stringify(this.state.environmentList));
        temp.forEach(element => {
            if (element._id===id){
                if (!element.selected) element.selected=true;
                else element.selected=false;
            }
        });



        let ind=false;
        temp.forEach(element => {
            if (!element.selected) ind=true
        });

        let found=false;
        temp.forEach(element => {
            if (element.selected) {
                found=true
            }
        });


        let allcheck=true
        if (!found){
            allcheck=false;
            ind=false;
        }

        this.setState({environmentList:temp,allEnvironmentInd:ind,allEnvironmentCheck:allcheck})
    }

    environmentHandleAllChange(){
        this.setState({allEnvironmentCheck:!this.state.allEnvironmentCheck},()=>{
            let temp=JSON.parse(JSON.stringify(this.state.environmentList));
            temp.forEach(element => {
                element.selected=this.state.allEnvironmentCheck;
            });

            this.setState({environmentList:temp,allEnvironmentInd:false})
        })
    }


    typeHandleChange(e){
        this.setState({typeValue:e.target.value})
    }

    sharedTypeHandleChange(e){
        this.setState({sharedTypeValue:e.target.value})
    }

    openFilterDialog(){
        this.setState({dialogOpen:true,showChart:false})
    }

    dialogHandleClose(){
        this.setState({dialogOpen:false})
    }

    passwordHandleClose(){
        this.setState({passwordDialogOpen:false})
    }

    dialogHandleConfirm(){
        let splitstring = this.state.sortValue.split(" - ");

        let sortColumn="";
        let sortOrder="";

        switch (splitstring[0]) {
            case "تعداد":{
                sortColumn="count";
                break;
            }
            case "نام و نام خانوادگی":{
                sortColumn="first_name";
                break;
            }
            case "شهر":{
                sortColumn="city";
                break;
            }
            case "تاریخ":{
                sortColumn="created_at";
                break;
            }
            case "زمان کارکرد":{
                sortColumn="working_time";
                break;
            }
            case "زمان بلااستفاده":{
                sortColumn="standby_time";
                break;
            }
            case "عنوان":{
                sortColumn="title";
                break;
            }
            default:
                break;
        }

        if (splitstring[1]==='صعودی'){
            sortOrder="ASC"
        }else{
            sortOrder="DESC"
        }

        switch (this.state.menuSelectedIndex) {
            case 0:{
                this.getCategory(1,sortColumn,sortOrder,this.state.sortValue,false);
                break;
            }
            case 1:{
                this.getProducts(1,sortColumn,sortOrder,this.state.sortValue,false);
                break;
            }
            case 2:{
                this.getContacts(1,sortColumn,sortOrder,this.state.sortValue);
                break;
            }
            case 3:{
                this.getUpTime(1,sortColumn,sortOrder,this.state.sortValue);
                break;
            }
            case 4:{
                this.getLiked(1,sortColumn,sortOrder,this.state.sortValue,false);
                break;
            }
            case 5:{
                let col="";
                let order="";
                let coltitle=""
                let ordertitle=""
                if (this.state.sharedTypeValue==='accumulative') {
                    col="count"
                    order="DESC"
                    coltitle="تعداد"
                    ordertitle="نزولی"
                }else {
                    col="title"
                    order="ASC"
                    coltitle="عنوان"
                    ordertitle="صعودی"
                }

                this.getShared(1,col,order,coltitle + " - "+ordertitle,false);
                break;
            }
            default:
                break;
        }

        this.setState({dialogOpen:false})
    }

    onCategoryClick(){
        if (this.state.menuSelectedIndex!==0){
            this.setState({menuSelectedIndex:0},()=>{
                this.getCategory(1,"count","DESC",this.state.categorySort[0],false);
            })
        }
    }

    onProductsClick(){
        if (this.state.menuSelectedIndex!==1){
            this.setState({menuSelectedIndex:1},()=>{
                this.getProducts(1,"count","DESC",this.state.categorySort[0],false);
            })
        }
    }

    onContactsClick(){
        if (this.state.menuSelectedIndex!==2){
            this.setState({menuSelectedIndex:2},()=>{
                this.getContacts(1,"first_name","ASC",this.state.contactSort[0]);
            })
        }
    }

    onUpTimeClick(){
        if (this.state.menuSelectedIndex!==3){
            this.setState({menuSelectedIndex:3},()=>{
                this.getUpTime(1,"working_time","DESC",this.state.upTimeSort[0]);
            })
        }
    }

    onLikedClick(){
        if (this.state.menuSelectedIndex!==4){
            this.setState({menuSelectedIndex:4},()=>{
                this.getLiked(1,"count","DESC",this.state.categorySort[0],false);
            })
        }
    }

    onSharedClick(){
        if (this.state.menuSelectedIndex!==5){
            this.setState({menuSelectedIndex:5},()=>{
                let col="";
                let order="";
                let coltitle=""
                let ordertitle=""
                if (this.state.sharedTypeValue==='accumulative') {
                    col="count"
                    order="DESC"
                    coltitle="تعداد"
                    ordertitle="نزولی"
                }else {
                    col="title"
                    order="ASC"
                    coltitle="عنوان"
                    ordertitle="صعودی"
                }

                this.getShared(1,col,order,coltitle + " - "+ordertitle,false);
            })
        }
    }

    onFirstPageClick(){
        let splitstring = this.state.sortValue.split(" - ");

        let sortColumn="";
        let sortOrder="";

        switch (splitstring[0]) {
            case "تعداد":{
                sortColumn="count";
                break;
            }
            case "نام و نام خانوادگی":{
                sortColumn="first_name";
                break;
            }
            case "شهر":{
                sortColumn="city";
                break;
            }
            case "تاریخ":{
                sortColumn="created_at";
                break;
            }
            case "زمان کارکرد":{
                sortColumn="working_time";
                break;
            }
            case "زمان بلااستفاده":{
                sortColumn="standby_time";
                break;
            }
            case "عنوان":{
                sortColumn="title";
                break;
            }
            default:
                break;
        }

        if (splitstring[1]==='صعودی'){
            sortOrder="ASC"
        }else{
            sortOrder="DESC"
        }


        switch (this.state.menuSelectedIndex) {
            case 0:{
                this.getCategory(1,sortColumn,sortOrder,this.state.sortValue,false);
                break;
            }
            case 1:{
                this.getProducts(1,sortColumn,sortOrder,this.state.sortValue,false);
                break;
            }
            case 2:{
                this.getContacts(1,sortColumn,sortOrder,this.state.sortValue);
                break;
            }
            case 3:{
                this.getUpTime(1,sortColumn,sortOrder,this.state.sortValue);
                break;
            }
            case 4:{
                this.getLiked(1,sortColumn,sortOrder,this.state.sortValue,false);
                break;
            }
            case 5:{
                let col="";
                let order="";
                let coltitle=""
                let ordertitle=""
                if (this.state.sharedTypeValue==='accumulative') {
                    col="count"
                    order="DESC"
                    coltitle="تعداد"
                    ordertitle="نزولی"
                }else {
                    col="title"
                    order="ASC"
                    coltitle="عنوان"
                    ordertitle="صعودی"
                }

                this.getShared(1,col,order,coltitle + " - "+ordertitle,false);
                break;
            }
            default:
                break;
        }
    }

    onPreviousPageClick(){
        let splitstring = this.state.sortValue.split(" - ");

        let sortColumn="";
        let sortOrder="";

        switch (splitstring[0]) {
            case "تعداد":{
                sortColumn="count";
                break;
            }
            case "نام و نام خانوادگی":{
                sortColumn="first_name";
                break;
            }
            case "شهر":{
                sortColumn="city";
                break;
            }
            case "تاریخ":{
                sortColumn="created_at";
                break;
            }
            case "زمان کارکرد":{
                sortColumn="working_time";
                break;
            }
            case "زمان بلااستفاده":{
                sortColumn="standby_time";
                break;
            }
            case "عنوان":{
                sortColumn="title";
                break;
            }
            default:
                break;
        }

        if (splitstring[1]==='صعودی'){
            sortOrder="ASC"
        }else{
            sortOrder="DESC"
        }

        switch (this.state.menuSelectedIndex) {
            case 0:{
                this.getCategory(this.state.currentPage-1,sortColumn,sortOrder,this.state.sortValue,false);
                break;
            }
            case 1:{
                this.getProducts(this.state.currentPage-1,sortColumn,sortOrder,this.state.sortValue,false);
                break;
            }
            case 2:{
                this.getContacts(this.state.currentPage-1,sortColumn,sortOrder,this.state.sortValue);
                break;
            }
            case 3:{
                this.getUpTime(this.state.currentPage-1,sortColumn,sortOrder,this.state.sortValue);
                break;
            }
            case 4:{
                this.getLiked(this.state.currentPage-1,sortColumn,sortOrder,this.state.sortValue,false);
                break;
            }
            case 5:{
                let col="";
                let order="";
                let coltitle=""
                let ordertitle=""
                if (this.state.sharedTypeValue==='accumulative') {
                    col="count"
                    order="DESC"
                    coltitle="تعداد"
                    ordertitle="نزولی"
                }else {
                    col="title"
                    order="ASC"
                    coltitle="عنوان"
                    ordertitle="صعودی"
                }

                this.getShared(this.state.currentPage-1,col,order,coltitle + " - "+ordertitle,false);
                // this.getShared(this.state.currentPage-1,sortColumn,sortOrder,this.state.sortValue);
                break;
            }
            default:
                break;
        }
    }

    onNextPageClick(){
        let splitstring = this.state.sortValue.split(" - ");

        let sortColumn="";
        let sortOrder="";

        switch (splitstring[0]) {
            case "تعداد":{
                sortColumn="count";
                break;
            }
            case "نام و نام خانوادگی":{
                sortColumn="first_name";
                break;
            }
            case "شهر":{
                sortColumn="city";
                break;
            }
            case "تاریخ":{
                sortColumn="created_at";
                break;
            }
            case "زمان کارکرد":{
                sortColumn="working_time";
                break;
            }
            case "زمان بلااستفاده":{
                sortColumn="standby_time";
                break;
            }
            case "عنوان":{
                sortColumn="title";
                break;
            }
            default:
                break;
        }

        if (splitstring[1]==='صعودی'){
            sortOrder="ASC"
        }else{
            sortOrder="DESC"
        }

        switch (this.state.menuSelectedIndex) {
            case 0:{
                this.getCategory(this.state.currentPage+1,sortColumn,sortOrder,this.state.sortValue,false);
                break;
            }
            case 1:{
                this.getProducts(this.state.currentPage+1,sortColumn,sortOrder,this.state.sortValue,false);
                break;
            }
            case 2:{
                this.getContacts(this.state.currentPage+1,sortColumn,sortOrder,this.state.sortValue);
                break;
            }
            case 3:{
                this.getUpTime(this.state.currentPage+1,sortColumn,sortOrder,this.state.sortValue);
                break;
            }
            case 4:{
                this.getLiked(this.state.currentPage+1,sortColumn,sortOrder,this.state.sortValue,false);
                break;
            }
            case 5:{
                let col="";
                let order="";
                let coltitle=""
                let ordertitle=""
                if (this.state.sharedTypeValue==='accumulative') {
                    col="count"
                    order="DESC"
                    coltitle="تعداد"
                    ordertitle="نزولی"
                }else {
                    col="title"
                    order="ASC"
                    coltitle="عنوان"
                    ordertitle="صعودی"
                }

                this.getShared(this.state.currentPage+1,col,order,coltitle + " - "+ordertitle,false);
                // this.getShared(this.state.currentPage+1,sortColumn,sortOrder,this.state.sortValue);
                break;
            }
            default:
                break;
        }
    }

    onLastPageClick(){
        let splitstring = this.state.sortValue.split(" - ");
        // console.log(splitstring[0]);
        // console.log(splitstring[1]);

        let sortColumn="";
        let sortOrder="";

        switch (splitstring[0]) {
            case "تعداد":{
                sortColumn="count";
                break;
            }
            case "نام و نام خانوادگی":{
                sortColumn="first_name";
                break;
            }
            case "شهر":{
                sortColumn="city";
                break;
            }
            case "تاریخ":{
                sortColumn="created_at";
                break;
            }
            case "زمان کارکرد":{
                sortColumn="working_time";
                break;
            }
            case "زمان بلااستفاده":{
                sortColumn="standby_time";
                break;
            }
            case "عنوان":{
                sortColumn="title";
                break;
            }
            default:
                break;
        }

        if (splitstring[1]==='صعودی'){
            sortOrder="ASC"
        }else{
            sortOrder="DESC"
        }
        
        switch (this.state.menuSelectedIndex) {
            case 0:{
                this.getCategory(this.state.totalPages,sortColumn,sortOrder,this.state.sortValue,false);
                break;
            }
            case 1:{
                this.getProducts(this.state.totalPages,sortColumn,sortOrder,this.state.sortValue,false);
                break;
            }
            case 2:{
                this.getContacts(this.state.totalPages,sortColumn,sortOrder,this.state.sortValue);
                break;
            }
            case 3:{
                this.getUpTime(this.state.totalPages,sortColumn,sortOrder,this.state.sortValue);
                break;
            }
            case 4:{
                this.getLiked(this.state.totalPages,sortColumn,sortOrder,this.state.sortValue,false);
                break;
            }
            case 5:{
                let col="";
                let order="";
                let coltitle=""
                let ordertitle=""
                if (this.state.sharedTypeValue==='accumulative') {
                    col="count"
                    order="DESC"
                    coltitle="تعداد"
                    ordertitle="نزولی"
                }else {
                    col="title"
                    order="ASC"
                    coltitle="عنوان"
                    ordertitle="صعودی"
                }

                this.getShared(this.state.totalPages,col,order,coltitle + " - "+ordertitle,false);
                // this.getShared(this.state.totalPages,sortColumn,sortOrder,this.state.sortValue);
                break;
            }
            default:
                break;
        }
    }

    handleOpenList(e){
        console.log(e);
        this.setState({[e]:true})
    }

    handleCloseList(e){
        this.setState({[e]:false})
    }

    sortHandleChange(e){
        let splitstring = e.target.value.split(" - ");

        let sortColumn="";
        let sortOrder="";

        switch (splitstring[0]) {
            case "تعداد":{
                sortColumn="count";
                break;
            }
            case "نام و نام خانوادگی":{
                sortColumn="first_name";
                break;
            }
            case "شهر":{
                sortColumn="city";
                break;
            }
            case "تاریخ":{
                sortColumn="created_at";
                break;
            }
            case "زمان کارکرد":{
                sortColumn="working_time";
                break;
            }
            case "زمان بلااستفاده":{
                sortColumn="standby_time";
                break;
            }
            case "عنوان":{
                sortColumn="title";
                break;
            }
            default:
                break;
        }

        if (splitstring[1]==='صعودی'){
            sortOrder="ASC"
        }else{
            sortOrder="DESC"
        }

        this.setState({sortValue:e.target.value})

        switch (this.state.menuSelectedIndex) {
            case 0:{
                this.getCategory(this.state.currentPage,sortColumn,sortOrder,e.target.value,false);
                break;
            }
            case 1:{
                this.getProducts(this.state.currentPage,sortColumn,sortOrder,e.target.value,false);
                break;
            }
            case 2:{
                this.getContacts(this.state.currentPage,sortColumn,sortOrder,e.target.value);
                break;
            }
            case 3:{
                this.getUpTime(this.state.currentPage,sortColumn,sortOrder,e.target.value);
                break;
            }
            case 4:{
                this.getLiked(this.state.currentPage,sortColumn,sortOrder,e.target.value,false);
                break;
            }
            case 5:{
                this.getShared(this.state.currentPage,sortColumn,sortOrder,e.target.value,false);
                break;
            }
            default:
                break;
        }
        // console.log(e);
    }

    onExit(){
        localStorage.clear();
        this.props.history.push('/login')
    }

    handleDrawerToggle(){
        this.setState({mobileOpen:!this.state.mobileOpen})
    }

    openChartDialog(){
        switch (this.state.menuSelectedIndex) {
            case 0:{
                this.setState({dialogOpen:true,chartLoading:true,chartTitle:'نمودار دسته‌بندی‌ها',showChart:true,argumentField:'title',valueField:'count'},()=>{
                    this.getCategory(0,"","","",true);
                })
                break;
            }
            case 1:{
                this.setState({dialogOpen:true,showChart:true,chartLoading:true,argumentField:'product_name',valueField:'count',chartTitle:'نمودار محصولات'},()=>{
                    this.getProducts(0,"","","",true);
                })
                break;
            }
            case 3:{
                let value="";
                if (this.state.sortValue.indexOf('زمان کارکرد')>-1) value="working_time"
                else value="standby_time"

                this.setState({dialogOpen:true,showChart:true,chartLoading:true,argumentField:'title',valueField:value,chartTitle:'نمودار عملکرد دستگاه‌ها'},()=>{
                    this.getUpTime(0,"","","",true);
                })
                break;
            }
            case 4:{
                this.setState({dialogOpen:true,showChart:true,chartLoading:true,argumentField:'title',valueField:'count',chartTitle:'نمودار دکورهای مورد علاقه'},()=>{
                    this.getLiked(0,"","","",true);
                })
                break;
            }
            case 5:{
                this.setState({dialogOpen:true,showChart:true,chartLoading:true,argumentField:'title',valueField:'count',chartTitle:'نمودار دکورهای اشتراک گذاشته شده'},()=>{
                    this.getShared(0,"","","",true);
                })
                break;
            }
            default:
                break;
        }
    }

    exportExcel(){
        let splitstring = this.state.sortValue.split(" - ");

        let sortColumn="";
        let sortOrder="";

        switch (splitstring[0]) {
            case "تعداد":{
                sortColumn="count";
                break;
            }
            case "نام و نام خانوادگی":{
                sortColumn="first_name";
                break;
            }
            case "شهر":{
                sortColumn="city";
                break;
            }
            case "تاریخ":{
                sortColumn="created_at";
                break;
            }
            case "زمان کارکرد":{
                sortColumn="working_time";
                break;
            }
            case "زمان بلااستفاده":{
                sortColumn="standby_time";
                break;
            }
            case "عنوان":{
                sortColumn="title";
                break;
            }
            default:
                break;
        }

        if (splitstring[1]==='صعودی'){
            sortOrder="ASC"
        }else{
            sortOrder="DESC"
        }

        switch (this.state.menuSelectedIndex) {
            case 0:{
                this.getCategory(this.state.currentPage,sortColumn,sortOrder,this.state.sortValue,false,true);
                break;
            }
            case 1:{
                this.getProducts(this.state.currentPage,sortColumn,sortOrder,this.state.sortValue,false,true);
                break;
            }
            case 2:{
                this.getContacts(this.state.currentPage,sortColumn,sortOrder,this.state.sortValue,true);
                break;
            }
            case 3:{
                this.getUpTime(this.state.currentPage,sortColumn,sortOrder,this.state.sortValue,false,true);
                break;
            }
            case 4:{
                this.getLiked(this.state.currentPage,sortColumn,sortOrder,this.state.sortValue,false,true);
                break;
            }
            case 5:{
                this.getShared(this.state.currentPage,sortColumn,sortOrder,this.state.sortValue,false,true);
                break;
            }
            default:
                break;
        }
    }

    onChangePassword(){
        this.setState({passwordDialogOpen:true,password1Value:'',password2Value:'',password3Value:'',password1ErrorText:'',password2ErrorText:'',password3ErrorText:''})
    }

    onChangePasswordConfirm(){
        this.setState({})
        if (!this.state.password1Value){
            this.setState({password1ErrorText:'کلمه عبور فعلی را پر کنید'})
            this.pass1Input.current.focus();
            return
        }else this.setState({password1ErrorText:''})

        if (!this.state.password2Value){
            this.setState({password2ErrorText:'کلمه عبور فعلی را پر کنید'})
            this.pass2Input.current.focus();
            return
        }else this.setState({password2ErrorText:''})

        if (!this.state.password3Value){
            this.setState({password3ErrorText:'کلمه عبور فعلی را پر کنید'})
            this.pass3Input.current.focus();
            return
        }else this.setState({password3ErrorText:''})

        if (this.state.password2Value !==this.state.password3Value){
            this.setState({password3ErrorText:'تکرار کلمه عبور اشتباه است'})
            this.pass3Input.current.focus();
            return
        }else this.setState({password3ErrorText:''})


        let data = {
            username: localStorage.getItem("username"),
            token:localStorage.getItem("token"),
            cpassword:this.state.password1Value,
            npassword:this.state.password3Value,
        };

        console.log(data);

        fetch("/api/changePassword",{
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        })
        .then(res => res.json())
        .then(
        (result) => {
            console.log(result);
            if (result.status===200){
                this.setState({passwordDialogOpen:false,snackbarColor:'var(--green1)',snackbarOpen:true,snackbarMessage:'کلمه عبور با موفقیت تغییر کرد'})
            }else {
                this.setState({snackbarColor:'var(--red1)',snackbarOpen:true,snackbarMessage:result.message})
            }
        })
    }

    inputHandleChange(e){
        this.setState({[e.target.name]:e.target.value})
    }

    handleKeyPress(target){
        if(target.charCode===13){
        this.onChangePasswordConfirm();  
        }
    }

    snackbarClose(){
        this.setState({snackbarOpen:false})
    }

    closeStandList(){
        let found=false;
        this.state.standList.forEach(element => {
            if (element.selected) found=true
        });
        if (!found){
            this.setState({snackbarColor:'var(--red1)',snackbarOpen:true,snackbarMessage:"یکی از موارد باید انتخاب شود"})
            return
        }
        this.setState({openStandList:false})
    }

    closeCategoryList(){
        let found=false;
        this.state.categoryList.forEach(element => {
            if (element.selected) found=true
        });
        if (!found){
            this.setState({snackbarColor:'var(--red1)',snackbarOpen:true,snackbarMessage:"یکی از موارد باید انتخاب شود"})
            return
        }
        this.setState({openCategoryList:false})
    }

    closeEnvironmentList(){
        let found=false;
        this.state.environmentList.forEach(element => {
            if (element.selected) found=true
        });
        if (!found){
            this.setState({snackbarColor:'var(--red1)',snackbarOpen:true,snackbarMessage:"یکی از موارد باید انتخاب شود"})
            return
        }
        this.setState({openEnvironmentList:false})
    }

    render() {
        const drawer = (
            <div>
                <div style={{display:'flex',flexDirection:'column',alignItems:'center',marginBottom:'5vh',marginTop:'2vh'}}>
                    <img src={profile} alt="profile" style={{width:'50px',objectFit:'contain'}} />
                    <div style={{margin:'10px 0px',color:'var(--black1)'}}>{localStorage.getItem("username")}</div>
                    <div className="linkStyle2" onClick={this.onChangePassword.bind(this)}>تغییر کلمه عبور</div>
                    <div className="linkStyle" onClick={this.onExit.bind(this)}>خروج</div>
                </div>
                <List>
                    <Divider />
                    <ListItem selected={this.state.menuSelectedIndex===0} button onClick={this.onCategoryClick.bind(this)}>
                        <ListItemText primary="دسته بندی‌ها" />
                    </ListItem>
                    <Divider />
                    <ListItem selected={this.state.menuSelectedIndex===1} button onClick={this.onProductsClick.bind(this)}>
                        <ListItemText primary="محصولات" />
                    </ListItem>
                    <Divider />
                    <ListItem selected={this.state.menuSelectedIndex===2} button onClick={this.onContactsClick.bind(this)}>
                        <ListItemText primary="ثبت نام‌ها" />
                    </ListItem>
                    <Divider />
                    <ListItem selected={this.state.menuSelectedIndex===3} button onClick={this.onUpTimeClick.bind(this)}>
                        <ListItemText primary="عملکرد دستگاه‌ها" />
                    </ListItem>
                    <Divider />
                    <ListItem selected={this.state.menuSelectedIndex===4} button onClick={this.onLikedClick.bind(this)}>
                        <ListItemText primary="دکورهای مورد علاقه" />
                    </ListItem>
                    <Divider />
                    <ListItem selected={this.state.menuSelectedIndex===5} button onClick={this.onSharedClick.bind(this)}>
                        <ListItemText primary="دکورهای اشتراک گذاشته شده" />
                    </ListItem>
                    <Divider />
                </List>
            </div>
        );
        const { classes } = this.props;
        if (this.state.serverResponse){
            return (
                <div className="dashboardMain">
                    <Snackbar
                        open={this.state.snackbarOpen}
                        onClose={this.snackbarClose.bind(this)}
                        TransitionComponent={SlideTransition}
                        message={this.state.snackbarMessage}
                        autoHideDuration={6000}
                        ContentProps={{
                            style: {
                                backgroundColor: this.state.snackbarColor
                            }
                        }}
                    />

                    <nav className={classes.drawer}>
                        <Hidden smUp implementation="css">
                        <Drawer
                            // container={container}
                            variant="temporary"
                            anchor={'left'}
                            open={this.state.mobileOpen}
                            onClose={this.handleDrawerToggle.bind(this)}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                        >
                            {drawer}
                        </Drawer>
                        </Hidden>
                        <Hidden xsDown implementation="css">
                        <Drawer
                            variant="permanent"
                            open
                        >
                            {drawer}
                        </Drawer>
                        </Hidden>
                        </nav>
                    
                    <div className="dashboardMiddle">
                        <div className="dashboardTitle">
                            <img src={logo} alt="profile" style={{width:'100px',objectFit:'contain'}} />
                            <IconButton
                                color="primary"
                                aria-label="open drawer"
                                // edge="start"
                                onClick={this.handleDrawerToggle.bind(this)}
                                className="menuIcon"
                            >
                                <MenuIcon />
                            </IconButton>
                        </div>
                        {/* className="formControl" */}
                        <div className="dashbaordContent">
                            <div className="dashbaordFiltersContainer">
                            
                                {!this.state.gridLoading && <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',flexGrow:'1',marginLeft:'10px'}}>
                                    <Button startIcon={<FilterIcon/>} className={classes.filterButton} variant="contained" color="primary" onClick={this.openFilterDialog.bind(this)}>{this.state.width>=700 && <div>اعمال فیلتر</div>}</Button>
                                    <div style={{display:'flex',flexDirection:'row'}}>
                                        {this.state.rowData.length>0 && this.state.menuSelectedIndex!==2 && (this.state.menuSelectedIndex!==5 || (this.state.menuSelectedIndex===5 && this.state.sharedTypeValue!=='separation')) && <Button startIcon={<ChartIcon/>} className={classes.filterButton} variant="contained" color="primary" onClick={this.openChartDialog.bind(this)}>{this.state.width>=700 && <div>نمودار</div>}</Button>}
                                        <div style={{minWidth:'10px'}} />
                                        {this.state.rowData.length>0 && <Button startIcon={!this.state.downloadLoading ? <DownloadIcon/>:null} className={classes.filterButton} variant="contained" color="primary" onClick={this.exportExcel.bind(this)}>{this.state.width>=700 && !this.state.downloadLoading && <div>خروجی اکسل</div>}{this.state.downloadLoading && <CircularProgress size={20} color="secondary" />}</Button>}
                                    </div>
                                    
                                </div>}

                                {!this.state.gridLoading && <FormControl>
                                    <InputLabel id="demo-simple-select-label" className={classes.selectStyle}>به ترتیب</InputLabel>
                                    <Select
                                    className={classes.selectStyle}
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={this.state.sortValue}
                                    onChange={this.sortHandleChange.bind(this)}>
                                    {this.state.sortList.map((item,i)=>{
                                        return <MenuItem key={i} className={classes.selectStyle} value={item}>{item}</MenuItem>
                                    })}
                                    </Select>
                                </FormControl>}


                                
                                <Dialog open={this.state.passwordDialogOpen} onClose={this.passwordHandleClose.bind(this)}>
                                    <div className="dialogTitle">تغییر کلمه عبور</div>
                                    <DialogContent>
                                        <TextField
                                            // className={classes.textFieldStyle}
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            name="password1Value"
                                            label="کلمه عبور فعلی"
                                            type="password"
                                            id="password1"
                                            color="primary"
                                            autoFocus
                                            helperText={this.state.password1ErrorText}
                                            value={this.state.password1Value}
                                            onChange={this.inputHandleChange}
                                            disabled={this.state.buttonDisabled}
                                            onKeyPress={this.handleKeyPress.bind(this)}
                                            inputRef={this.pass1Input}
                                            inputProps={{style: { direction: "ltr"}}}
                                            dir="ltr"
                                        />
                                        <div style={{minHeight:'20px'}} />
                                        <TextField
                                            className={classes.textFieldStyle}
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            name="password2Value"
                                            label="کلمه عبور جدید"
                                            type="password"
                                            id="password2"
                                            color="primary"
                                            helperText={this.state.password2ErrorText}
                                            value={this.state.password2Value}
                                            onChange={this.inputHandleChange}
                                            disabled={this.state.buttonDisabled}
                                            onKeyPress={this.handleKeyPress.bind(this)}
                                            inputRef={this.pass2Input}
                                            inputProps={{style: { direction: "ltr" } }}
                                            dir="ltr"
                                        />
                                        <div style={{minHeight:'20px'}} />
                                        <TextField
                                            className={classes.textFieldStyle}
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            name="password3Value"
                                            label="تکرار کلمه عبور جدید"
                                            type="password"
                                            id="password3"
                                            color="primary"
                                            helperText={this.state.password3ErrorText}
                                            value={this.state.password3Value}
                                            onChange={this.inputHandleChange}
                                            disabled={this.state.buttonDisabled}
                                            onKeyPress={this.handleKeyPress.bind(this)}
                                            inputRef={this.pass3Input}
                                            inputProps={{style: { direction: "ltr" } }}
                                            dir="ltr"
                                        />
                                        <div style={{minHeight:'20px'}} />

                                    </DialogContent>
                                    <DialogActions>
                                    <Button onClick={this.passwordHandleClose.bind(this)} color="primary">
                                        انصراف
                                    </Button>
                                    <Button onClick={this.onChangePasswordConfirm.bind(this)} color="primary">
                                        تایید
                                    </Button>
                                    </DialogActions>
                                </Dialog>
                                


                                <Dialog open={this.state.dialogOpen} onClose={this.dialogHandleClose.bind(this)}>
                                    {!this.state.showChart && <div className="dialogTitle">فیلترهای مورد نظر خور را اعمال کنید</div>}
                                    {this.state.showChart && <div className="dialogTitle">{this.state.chartTitle}</div>}
                                    <DialogContent>
                                    {this.state.showChart && this.state.chartLoading && <div style={{display:'flex',justifyContent:'center',alignItems:'center',width:'100%',minHeight:'250px'}}><CircularProgress color="primary" /></div>}
                                    {this.state.showChart && !this.state.chartLoading && <div className="chartDialog">
                                        {this.state.menuSelectedIndex !== 3 && <PieChart
                                            id="pie"
                                            dataSource={this.state.chartData}
                                            palette="Material"
                                            resolveLabelOverlapping='shift'>                                            
                                            <Legend orientation="horizontal"
                                            itemTextPosition="left"
                                            horizontalAlignment="center"
                                            verticalAlignment="bottom">
                                                <Font color="var(--black1)" family="IRANSansMobile" size={11}/>
                                            </Legend>
                                            <Series
                                            argumentField={this.state.argumentField}
                                            valueField={this.state.valueField}
                                            >
                                            <Label visible={true} customizeText={this.formatTime2} wordWrap="none" textOverflow="none">
                                                <Connector visible={true} width={1} />
                                                <Font color="white" family="IRANSansMobile"/>
                                            </Label>
                                            <SmallValuesGrouping topCount={10} mode="topN" groupName="سایر" />
                                            </Series>

                                            <Size height={this.state.height*0.6} width={this.state.width*0.6} />
                                            <Export enabled={true} />
                                        </PieChart>}


                                        {this.state.menuSelectedIndex === 3 && <Chart id="chart"
                                            resolveLabelOverlapping="stack"
                                            dataSource={this.state.chartData}
                                            rtlEnabled>
                                            <ArgumentAxis> {/* or ValueAxis, or CommonAxisSettings */}
                                                <Label
                                                overlappingBehavior="rotate"
                                                rotationAngle={90}
                                                // staggeringSpacing={20}
                                                // displayMode="stagger"
                                                >
                                                    <Font family="IRANSansMobile"/>
                                                </Label>
                                            </ArgumentAxis>

                                            <ValueAxis> {/* or ValueAxis, or CommonAxisSettings */}
                                                <Label
                                                customizeText={this.formatTime2}
                                                >
                                                    <Font family="IRANSansMobile"/>
                                                </Label>
                                            </ValueAxis>

                                            <CommonSeriesSettings
                                            argumentField="title"
                                            type="bar"
                                            hoverMode="allArgumentPoints"
                                            selectionMode="allArgumentPoints"
                                            >
                                            <Label visible={true} customizeText={this.formatTime2} wordWrap="none" textOverflow="none">
                                                <Font color="white" family="IRANSansMobile"/>
                                            </Label>
                                            </CommonSeriesSettings>
                                            <Series
                                                argumentField="title"
                                                valueField="working_time"
                                                name="زمان کارکرد"
                                            />
                                            <Series
                                                valueField="standby_time"
                                                name="زمان بلااستفاده"
                                            />
                                            <Legend orientation="horizontal"
                                            itemTextPosition="left"
                                            horizontalAlignment="center"
                                            verticalAlignment="bottom">
                                                <Font color="var(--black1)" family="IRANSansMobile" size={11}/>
                                            </Legend>
                                            <Size height={this.state.height*0.7} width={this.state.width*0.9} />
                                            <Export enabled={true} />
                                        </Chart>}
                                    </div>}
                                    {!this.state.showChart && <div className="filterDialog">
                                        <DatePicker
                                            calendarPosition={`bottom-center`}
                                            ref={this.datepickerr1}
                                            value={this.state.dateValue1}
                                            render={<StartDateInput />}
                                            calendar="persian"
                                            locale="fa"
                                            weekDays={[
                                                "ش", 
                                                "ی", 
                                                "د", 
                                                "س", 
                                                "چ", 
                                                "پ", 
                                                "ج"
                                            ]}
                                            format="YYYY/MM/DD"
                                            animation
                                            className="rmdp-mobile bluee"
                                            onChange={valuee => this.dateChange1(valuee)}
                                            editable={false}
                                            inputMode="none"
                                            maxDate={this.state.maxDateStart}
                                            type="custom"
                                        />

                                        

                                        <div style={{minHeight:'20px'}} />

                                        <DatePicker
                                            calendarPosition={`bottom-center`}
                                            ref={this.datepickerr}
                                            value={this.state.dateValue}
                                            render={<EndDateInput />}
                                            calendar="persian"
                                            locale="fa"
                                            weekDays={[
                                                "ش", 
                                                "ی", 
                                                "د", 
                                                "س", 
                                                "چ", 
                                                "پ", 
                                                "ج"
                                            ]}
                                            format="YYYY/MM/DD"
                                            animation
                                            className="rmdp-mobile bluee"
                                            onChange={valuee => this.dateChange(valuee)}
                                            minDate={this.state.minDate}
                                            maxDate={this.state.maxDate}
                                            editable={false}
                                            inputMode="none"
                                            type="custom"
                                        />
                                        <div style={{minHeight:'20px'}} />

                                        {this.state.standList.length>0 && <FormControl>
                                            <Button id="standMenuButton" onClick={this.handleOpenList.bind(this,'openStandList')} variant="contained" color="primary">
                                                انتخاب استند
                                            </Button>
                                            
                                            {this.state.openStandList && <div className="listContainerParentBG">
                                                <div className="listContainerParentBG" onClick={this.closeStandList.bind(this)}/>
                                                <div className="listContainerParent">
                                                    <div className="listContainer">
                                                        <div className="listItem"><MenuItem value={"all"} onClick={this.standHandleAllChange.bind(this)}>
                                                            <Checkbox color="primary" indeterminate={this.state.allStandInd} checked={this.state.allStandCheck} />
                                                            <ListItemText primary="همه" />
                                                        </MenuItem></div>
                                                        <Divider/>
                                                        {this.state.standList.map((item, i) => (
                                                        <div className="listItem"><MenuItem key={i} value={item._id} onClick={this.standHandleChange.bind(this,item._id)}>
                                                            <Checkbox color="primary" checked={item.selected} />
                                                            <ListItemText primary={item.title} />
                                                        </MenuItem></div>
                                                        ))}
                                                    </div>

                                                    <Button onClick={this.closeStandList.bind(this)} className={classes.root} variant="contained" color="primary">
                                                        تایید
                                                    </Button>
                                                </div>
                                                
                                            </div>}
                                        </FormControl>}

                                        {this.state.categoryList.length>0 && this.state.menuSelectedIndex===1 && <div style={{minHeight:'20px'}} />}
                                        {this.state.categoryList.length>0 && this.state.menuSelectedIndex===1 && <FormControl>
                                            <Button id="categoryMenuButton" onClick={this.handleOpenList.bind(this,'openCategoryList')} variant="contained" color="primary">
                                                انتخاب دسته
                                            </Button>
                                            
                                            
                                            {this.state.openCategoryList && <div className="listContainerParentBG">
                                                <div className="listContainerParentBG" onClick={this.closeCategoryList.bind(this)}/>
                                                <div className="listContainerParent">
                                                    <div className="listContainer">
                                                        <div className="listItem"><MenuItem value={"all"} onClick={this.categoryHandleAllChange.bind(this)}>
                                                            <Checkbox color="primary" indeterminate={this.state.allCategoryInd} checked={this.state.allCategoryCheck} />
                                                            <ListItemText primary="همه" />
                                                        </MenuItem></div>
                                                        <Divider/>
                                                        {this.state.categoryList.map((item, i) => (
                                                        <div className="listItem"><MenuItem key={i} value={item._id} onClick={this.categoryHandleChange.bind(this,item._id)}>
                                                            <Checkbox color="primary" checked={item.selected} />
                                                            <ListItemText primary={item.title.fa} />
                                                        </MenuItem></div>
                                                        ))}
                                                    </div>
                                                    <Button onClick={this.closeCategoryList.bind(this)} className={classes.root} variant="contained" color="primary">
                                                        تایید
                                                    </Button>
                                                </div>
                                            </div>}
                                        </FormControl>}

                                        {this.state.environmentList.length>0 && (this.state.menuSelectedIndex===4 || this.state.menuSelectedIndex===5) && <div style={{minHeight:'20px'}} />}
                                        {this.state.environmentList.length>0 && (this.state.menuSelectedIndex===4 || this.state.menuSelectedIndex===5) && <FormControl>
                                            <Button id="environmentMenuButton" onClick={this.handleOpenList.bind(this,'openEnvironmentList')} variant="contained" color="primary">
                                                انتخاب محیط
                                            </Button>
                                            {this.state.openEnvironmentList && <div className="listContainerParentBG">
                                                <div className="listContainerParentBG" onClick={this.closeEnvironmentList.bind(this)}/>
                                                <div className="listContainerParent">
                                                    <div className="listContainer">
                                                        <div className="listItem"><MenuItem value={"all"} onClick={this.environmentHandleAllChange.bind(this)}>
                                                            <Checkbox color="primary" indeterminate={this.state.allEnvironmentInd} checked={this.state.allEnvironmentCheck} />
                                                            <ListItemText primary="همه" />
                                                        </MenuItem></div>
                                                        <Divider/>
                                                        {this.state.environmentList.map((item, i) => (
                                                        <div className="listItem"><MenuItem key={i} value={item._id} onClick={this.environmentHandleChange.bind(this,item._id)}>
                                                            <Checkbox color="primary" checked={item.selected} />
                                                            <ListItemText primary={item.title.fa} />
                                                        </MenuItem></div>
                                                        ))}
                                                    </div>
                                                    <Button onClick={this.closeEnvironmentList.bind(this)} className={classes.root} variant="contained" color="primary">
                                                        تایید
                                                    </Button>
                                                </div>
                                            </div>}
                                        </FormControl>}

                                        <div style={{minHeight:'30px'}} />

                                        {this.state.menuSelectedIndex===5 && <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">نوع</InputLabel>
                                            <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={this.state.sharedTypeValue}
                                            MenuProps={MenuProps}
                                            onChange={this.sharedTypeHandleChange.bind(this)}>
                                            <MenuItem value="separation">تفکیکی</MenuItem>
                                            <MenuItem value="accumulative">تجمیعی</MenuItem>
                                            </Select>
                                        </FormControl>}
                                        {this.state.menuSelectedIndex===1 && <FormControl component="fieldset" >
                                            <FormLabel component="legend">نوع</FormLabel>
                                            <RadioGroup aria-label="gender" name="gender1" value={this.state.typeValue}  onChange={this.typeHandleChange.bind(this)}>
                                                <FormControlLabel value="all" control={<Radio color="primary" />} label="همه" />
                                                <FormControlLabel value="select" control={<Radio color="primary" />} label="انتخاب شده‌ها" />
                                                <FormControlLabel value="like" control={<Radio color="primary" />} label="لایک شده‌ها" />
                                            </RadioGroup>
                                        </FormControl>}
                                    </div>}
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={this.dialogHandleClose.bind(this)} color="primary">
                                            انصراف
                                        </Button>
                                        {!this.state.showChart && <Button onClick={this.dialogHandleConfirm.bind(this)} color="primary">
                                            تایید
                                        </Button>}
                                    </DialogActions>
                                </Dialog>
                            </div>


                            <div className="ag-theme-alpine" style={{minHeight: 'calc(100vh - 240px)', width: '100%',marginTop:'20px',position:'relative'}}>
                                {!this.state.gridLoading && <AgGridReact
                                    enableRtl={true}
                                    autoHeight={true}
                                    animateRows={true}
                                    enableCharts={true}
                                    enableBrowserTooltips={true}
                                    tooltipMouseTrack={true}
                                    suppressSizeToFit={false}
                                    columnDefs={this.state.columnDefs}
                                    localeText={this.state.gridLocaleText}
                                    rowData={this.state.rowData}
                                    suppressDragLeaveHidesColumns ={true}
                                    suppressMovable={true}
                                    onGridReady={this.onGridReady.bind(this)}
                                    rowHeight={28}
                                    headerHeight={35}
                                    frameworkComponents={this.state.frameworkComponents}
                                    // onRowClicked={this.onGridRowClicked.bind(this)}
                                    getRowStyle ={function(params){ return { borderWidth:'0px' }}}
                                    ref={this.tableGrid}
                                    >
                                </AgGridReact>}
                                
                                {this.state.gridLoading && <div className="tableLoadingContainer"><CircularProgress color="primary" /></div>}
                            </div>
                            {!this.state.gridLoading && this.state.rowData.length>0 && this.state.totalPages>1 && <div style={{width:'100%',marginTop:'15px',display:'flex',justifyContent:'center'}}><Paginator currentPage={this.state.currentPage} totalPages={this.state.totalPages} onFirstPageClick={this.onFirstPageClick.bind(this)} onPreviousPageClick={this.onPreviousPageClick.bind(this)} onNextPageClick={this.onNextPageClick.bind(this)} onLastPageClick={this.onLastPageClick.bind(this)} /></div>}
                        </div>
                    </div>
                </div>
            )
        }else{
            return <CircularProgress color="primary" />
        }
        
    }
}
export default withStyles(useStyles)(Dashboard)