import React, { Component } from 'react'
import SignIn from './pages/SignIn'
import Dashboard from './pages/Dashboard'
import NotFound from './pages/NotFound'
import './App.css';
import history from './history';
import { Router, Switch, Route } from "react-router-dom";
import {
  StylesProvider,
  jssPreset,
  MuiThemeProvider,
  createTheme
} from "@material-ui/core/styles";
import { create } from "jss";
import rtl from "jss-rtl";
import 'devextreme/dist/css/dx.material.orange.light.compact.css';



const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const rtlTheme = createTheme({ 
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
      middle: 700,
    },
  },
  direction: "rtl",
  typography: {fontFamily: ['IRANSansMobile']},
  palette: {
    primary: {
      main: '#e50000',
    },
    secondary: {
      main: '#ebebeb',
    },
  },
});

export default class App extends Component {
  constructor(props){
    super(props);

    document.body.setAttribute("dir", "rtl");
  }
  
  render() {
    return (
      <StylesProvider jss={jss}>
      <MuiThemeProvider theme={rtlTheme}>
      <div className="app">
        <Router history={history}>
            <Switch>
                <Route path='/Login' render={(props) => (<SignIn {...props} />)}/>
                <Route path="/" exact render={(props) => (<Dashboard {...props}/>)}/>
                <Route component={NotFound} />
            </Switch>
        </Router>
      </div>
      </MuiThemeProvider>
      </StylesProvider>
    )
  }
}
